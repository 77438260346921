import router, {
  resetRouter,
  shopRoutes,
  commonRoutes
} from '@/router/routerMain'
import $x from '@/libs/$x'

const Layer = resolve => require(['@/views/layer/layer.js'], resolve);
//店铺列表
const ShopList = resolve => require(['@/views/shop/shopList'], resolve);
//创建编辑店铺
const CreateShop = resolve => require(['@/views/shop/createShop'], resolve);

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  setRoutes: (state, routes) => {
    state.addRoutes = routes
    state.routes = commonRoutes.concat(routes)
  }
}

const getters = {
  navMenu: (state) => {
    if (state.addRoutes) {
      return state.addRoutes
    }
    return []
  }
}

const handleManageRouter = (oldRouter, resData, userinfo) => {
  let pathList = []
  // 通过id找出对应的router path
  resData.forEach(item => {
    if (item.id !== 1) {
      let targetObj = $x.mapper.manageMenuMapper[item.id]
      if (targetObj && targetObj.path) {
        pathList.push(targetObj.path)
      }
      if (item.children && item.children.length > 0) {
        item.children.forEach(inner => {
          let targetObj = $x.mapper.manageMenuMapper[inner.id]
          if (targetObj && targetObj.path) {
            pathList.push(targetObj.path)
          }
        })
      }
    }
  })
  oldRouter.forEach(item => {
    if (pathList.indexOf(item.path) > -1) {
      item.meta.show = true
    }
    if (item.children) {
      item.children.forEach(inner => {
        if (pathList.indexOf(inner.path) > -1) {
          inner.meta.show = true
          item.meta.show = true
        }
        if (inner.children) {
          for (let i = inner.children.length - 1; i >= 0; i--) {
            if (pathList.indexOf(inner.children[i].path) > -1) {
              inner.meta.show = true
              item.meta.show = true
              inner.redirect = inner.children[i].path
            }
          }
        }
      })
    }
  })

  // :dev
  // oldRouter.forEach(item => {
  //   item.meta.show = true
  //   if (item.children) {
  //     item.children.forEach(inner => {
  //       inner.meta.show = true
  //       if (inner.children) {
  //         for (let i = inner.children.length - 1; i >= 0; i--) {
  //           if (pathList.indexOf(inner.children[i].path) > -1) {
  //             inner.meta.show = true
  //             item.meta.show = true
  //             inner.redirect = inner.children[i].path
  //           }
  //         }
  //       }
  //     })
  //   }
  // })

  //添加判断 如果children下所有show都是false的 则不显示父的

  //管理员 选择店铺路由
  if (userinfo.userType === 1 || userinfo.userType === 0) {
    oldRouter.unshift({
      path: '/createShop',
      meta: {
        title: '创建店铺',
        icon: 'iconfont al-icon-gaikuang',
        icon_m: 'iconfont al-icon-gaikuang',
        show: false
      },
      redirect: '/createShop/index',
      component: Layer,
      children: [{
        path: '/createShop/index',
        name: 'createShop',
        component: CreateShop,
        meta: {
          title: '创建店铺',
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          show: false
        }
      }]
    })
    oldRouter.unshift({
      path: '/shopList',
      meta: {
        title: '店铺列表',
        icon: 'iconfont al-icon-gaikuang',
        icon_m: 'iconfont al-icon-gaikuang',
        show: false
      },
      redirect: '/shopList/index',
      component: Layer,
      children: [{
        path: '/shopList/index',
        name: 'shopList',
        component: ShopList,
        meta: {
          title: '店铺列表',
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          show: false
        }
      }]
    })
  }
  return oldRouter
}

const fixedRedirectRouter = (routerList)=>{
  routerList.forEach(item=>{
    if(item.redirect && item.children && item.children.length > 0){
      item.redirect = item.children[0].path
    }
  })
}

const actions = {
  //addRoutes若有 则设置路由为当前栈中首个路由
  toDefaultRouter({
    state,
    commit,
    dispatch
  }) {
    return new Promise((resolve, reject) => {
      console.log(state.addRoutes)
      if (state.addRoutes && state.addRoutes.length > 0) {
        //如果首个路由为店铺列表 则直接进入店铺列表
        if (state.addRoutes[0].path === '/shopList') {
          router.push(state.addRoutes[0].path)
        } else {
          state.addRoutes.some(item => {
            if (item.meta.show) {
              if (item.children) {
                let subTag = item.children.some(inner => {
                  if (inner.meta.show) {
                    router.push(inner.path)
                    return true
                  }
                })
                if (!subTag) {
                  router.push(item.path)
                }
              } else {
                router.push(item.path)
              }
              return true
            }
            return false
          })
        }
        resolve()
      }
    })
  },
  //初始化路由
  initRouter({
    commit,
    dispatch
  }, userInfo) {
    return new Promise(async (resolve, reject) => {
      resetRouter()
      let accessedRoutes = []
      //需另外调取接口获取实际的menu
      const {
        data,
        code,
        message
      } = await $x.get('/auth/tokenUserInfo')
      if (code === 200) {
        // accessedRoutes = handleManageRouter(shopRoutes, data['userPermission'], data['userinfo']) || []
        accessedRoutes = shopRoutes
        console.log(accessedRoutes)
        
        fixedRedirectRouter(accessedRoutes)

        commit('setRoutes', accessedRoutes)
        router.addRoutes(accessedRoutes)
      } else {
        reject(message)
      }
      // accessedRoutes = shopRoutes
      // commit('setRoutes', accessedRoutes)
      // router.addRoutes(accessedRoutes)
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
