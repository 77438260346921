'use strict'

class StorageUtil {
  constructor(storage) {
    this.storage = storage || localStorage
    // 缓存
    this.caches = {}
  }

  getItem(key) {
    var val = this.storage.getItem(key);
    if (!val) {
      return val;
    }
    val = JSON.parse(val);
    if (Date.now() - val.time > val.expire) {
      this.storage.removeItem(key);
      return null;
    }
    return val.data;
  }

  removeItem(key) {
    this.storage.removeItem(key)
  }

  //expire单位为毫秒
  setItem(key, value, expire) {
    expire = expire || 3 * 12 * 30 * 24 * 60 * 60 * 1000
    var obj = {
      data: value,
      time: Date.now(),
      expire: expire
    };
    this.storage.setItem(key, JSON.stringify(obj));
  }
}

export default StorageUtil
