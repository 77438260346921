import StorageUtil from './utils/storageUtil.js'
import platform from './utils/platform.js'
import tokenUtil from './utils/tokenUtil.js'
import domUtil from './utils/domUtil.js'
import msgDialog from './utils/msgDialog.js'
import httpUtil from './utils/httpUtil.js'
import mapper from './utils/mapper.js'
import initView from './utils/initView.js'
import validate from './utils/validate.js'
import initRules from './utils/initRules.js'
import deepCopy from './utils/deepCopy.js'
import formatData from './formatData.js'

const serverMap = require('../../config/serverMap.js')
const util = {
  // 预期的路由
  realRoutes(allRoute, nowRoute) {
    //返回当前的关联路由
    let nowPath = nowRoute.path
    let filterList = []

    function findAllPath(pathList) {
      pathList.forEach(item => {
        if (item.path.startsWith('/' + nowPath.split('/')[1])) {
          filterList.push(item)
        }
        if (item.children) {
          findAllPath(item.children)
        }
      })
    }
    findAllPath(allRoute)
    filterList = filterList.filter((item) => {
      if (nowPath.indexOf(item.path) === 0) {
        return true
      }
      return false
    }).sort((a, b) => {
      return a.path.split('/').length - b.path.split('/').length
    })
    return filterList
  },
  //获取上传action基础url
  getHttpBaseUrl() {
    return serverMap.base
  },
  //截取decimals位小数，不进行四舍五入
  cutOutNum(num, decimals = 2) {
    num = Number(num)

    if (isNaN(num) || (!num && num !== 0)) {
      return '-'
    }

    function toNonExponential(_num) {
      var m = _num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/)
      return _num.toFixed(Math.max(0, (m[1] || '').length - m[2]))
    }

    // 为了兼容科学计数法的数字
    num = toNonExponential(num)
    // 获取小数点的位置 + 1（不存在小数点的indexOf值为-1）
    const pointIndex = String(num).indexOf('.') + 1
    // 获取小数点后的个数(需要保证有小数位)
    const pointCount = pointIndex ? String(num).length - pointIndex : 0

    // 补零函数
    function zeroFill(zeroNum, num) {
      for (let index = 0; index < zeroNum; index++) {
        num = `${num}0`
      }
      return num
    }

    // 源数据为"整数"或者小数点后面小于decimals位的作补零处理
    if (pointIndex === 0 || pointCount <= decimals) {
      let tempNumA = num
      // 区分"整数"和"小数"的补零
      if (pointIndex === 0) {
        tempNumA = `${tempNumA}.`
        tempNumA = zeroFill(decimals - pointCount, tempNumA)
      } else {
        tempNumA = zeroFill(decimals - pointCount, tempNumA)
      }
      return String(tempNumA)
    }

    // 截取当前数据到小数点后decimals位
    const Int = String(num).split('.')[0]
    const Decimal = String(num).split('.')[1].substring(0, decimals)
    const tempNumB = `${Int}.${Decimal}`
    return Number(tempNumB) === 0 ? 0 : tempNumB
  },
  // async的forEach
  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  },
  //selection list
  setElSelection(selections, propName, dataList) {
    selections.some((item, index) => {
      if (item.prop === propName) {
        selections[index]['options'] = dataList
        return true
      }
    })
  }
}

var mixed = {
  isPc: platform.getPlat(),
  ...util,
  ...msgDialog,
  ...httpUtil,
  mapper,
  validate,
  initRules,
  initView,
  deepCopy,
  formatDate: formatData.formatDate,
  sessionStorage: new StorageUtil(sessionStorage),
  //将令牌由localStorage改为sessionStorage
  localStorage: new StorageUtil(),
  tokenUtil,
}

Object.assign(domUtil, mixed)

export default domUtil
