var configs = {
  //本地开发环境的接口地址(npm run dev)
  dev: {
    base: '//localhost:8089/myemlv/api/client',
    node_api: '//localhost:7001',
  },
  //待发布的开发环境的接口地址(npm run build-dev)
  dev_build: {
    base: '//xxx.dev61.xxx.com',
    node_api: '//xxx-node.dev61.xxx.com',
  },
  //测试环境的接口地址
  test: {
    base: '//dev.client.myemlv.com.cn/myemlv/api/client',
    node_api: '//bbb-node-service-test.xxx.com',
  },
  //演示环境的接口地址
  demo: {
    base: '//47.107.92.193/myemlv',
    node_api: '//bbb-node-service-demo.xxx.com',
  },
  //生产环境的接口地址
  //base: '//120.76.46.9/myemlv/api/v1',
  prod: {
    base: '//client.myemlv.com/myemlv/api/client',
    node_api: '//bbb-node-service.xxx.com',
  },
  prod_ip: {
    base: '//120.76.46.9/myemlv/api/v1',
    node_api: '//bbb-node-service.xxx.com',
  }
}
module.exports = configs[process.env.ENV_CONFIG]
