//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Upload from '@/component/pc/videoSelect/upload.vue';
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions, mapMutations } = createNamespacedHelpers('video');
export default {
  components: {
    Upload
  },
  props: {
    fileType: {
      type: [String, Number, Boolean, Object, Array],
      default: 2
    },
    /**
     * 上传限制
     * @type {Number}
     */
    limit: {
      type: Number,
      default: 1
    }
  },
  provide() {
    return {
      uploader: this
    };
  },
  data() {
    return {
      /**
       * 分类选中
       * @type {Number}
       */
      index: -1,
      /**
       * 编辑ID
       * @type {Number}
       */
      is_edit: 0,
      /**
       * 选中值
       * @type {Array}
       */
      checkedData: [],
      /**
       * 是否全选
       * @type {Boolean}
       */
      checkAll: false,
      /**
       * 半选中状态
       * @type {Boolean}
       */
      isIndeterminate: false,
      /**
       * 数据加载
       * @type {Boolean}
       */
      loading: false,
      /**
       * 处理分级
       */
      breadcrumb: [
        {
          id: -1,
          name: '全部'
        }
      ]
    };
  },
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {
    index(value) {
      //this.checkedData = [];
      let groupInfo = null;
      if (value == -1) {
        groupInfo = {
          id: -1,
          name: '全部'
        };
      } else {
        groupInfo = this.groupList[value];
      }
      this.breadcrumb = [groupInfo];
      //设置当前分组信息
      this.setGroupInfo(groupInfo);
      //加载当前分组数组
      this.LoadPhotoList({
        id: groupInfo.id,
        current: 1,
        pageSize: 15
      });
    },
    checkedData(value) {
      if (value.length > 0) {
        this.isIndeterminate = true;
      } else {
        this.checkAll = false;
        this.isIndeterminate = false;
      }
    }
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    ...mapState(['groupList', 'groupInfo', 'photoList', 'pageInfo']),
    inputType() {
      if (this.limit > 1) {
        return 'checkbox';
      } else {
        return 'radio';
      }
    },
    /**
     * 是否创建
     * @return {Boolean} [description]
     */
    is_make() {
      if (this.groupInfo.name == '全部') {
        return false;
      }
      if (this.groupInfo.name == '未分组') {
        return false;
      }
      let path = this.groupInfo.path || this.groupInfo.url || '0';
      if (path.split('-').length >= 3) {
        return false;
      }
      return true;
    },
    /**
     * 移动
     * @return {Boolean} [description]
     */
    is_move() {
      if (this.checkedData.length < 1) {
        return true;
      }
      let s = JSON.stringify(this.checkedData);
      if (s.indexOf('"type":0') > -1) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * 是否删除
     * @return {Boolean} [description]
     */
    is_delete() {
      if (this.checkedData.length < 1) {
        return true;
      }
      let s = JSON.stringify(this.checkedData);
      if (s.indexOf('"type":0') > -1) {
        if (this.checkedData.length > 1) {
          return true;
        }
      }
      return s.indexOf('"type":1') > -1 && s.indexOf('"type":0') > -1;
    },
    /**
     * 是否重命名
     * @return {Boolean} [description]
     */
    is_rename() {
      if (this.checkedData.length == 0) {
        return true;
      }
      if (this.checkedData.length > 1) {
        return true;
      }
      let s = JSON.stringify(this.checkedData);
      return s.indexOf('"type":1') > -1 && s.indexOf('"type":0') > -1;
    }
  },
  /**
   * 页面加载执行
   * @return {[type]} [description]
   */
  mounted() {
    this.LoadGroupList().then(() => {
      this.LoadPhotoList({
        id: -1,
        current: 1,
        pageSize: 15
      });
    });
  },
  methods: {
    ...mapMutations(['setGroupInfo']),
    ...mapActions([
      'LoadGroupList',
      'LoadPhotoList',
      'InterGroupItem',
      'UpdateGroupItem',
      'DeleteGroupItem',
      'DeletePhotoList',
      'MovePhotoList',
      'UpdatePhotoItem',
      'upadteGroupInfo'
    ]),
    /**
     * 执行创建分组
     * @return {[type]} [description]
     */
    handleMakeGroup(option, type = 1) {
      this.loading = true;
      this.InterGroupItem(option)
        .then(value => {
          if (type == 1) {
            this.index = -1;
            this.upadteGroupInfo({
              id: -1,
              name: '全部'
            });
            //重载分组列表
            this.LoadGroupList().then(res => {});
          }
          if (type == 2) {
            setTimeout(() => {
              let id = value.parent_id || this.groupInfo.id;
              //重载当前分组列表
              this.LoadPhotoList({ id: id, current: 1, pageSize: 15 }).then(res => {
                this.loading = false;
              });
              //this.checkedData = [];
            }, 500);
          }
        })
        .catch(error => {
          this.loading = false;
        });
    },
    /**
     * 执行删除数据
     * @return {[type]} [description]
     */
    handleGroupDelete(id, type = 1) {
      this.loading = true;
      this.$confirm('此分组及子级分组的图片都将移至未分组中， 是否确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      })
        .then(() => {
          this.DeleteGroupItem({ id, type })
            .then(() => {
              if (type == 1) {
                setTimeout(() => {
                  //重载当前分组列表
                  this.LoadPhotoList({ id: this.groupInfo.id, current: 1, pageSize: 15 }).then(res => {
                    this.loading = false;
                  });
                  //this.checkedData = [];
                }, 500);
              }
              if (type == 2) {
                this.index = -1;
                this.LoadGroupList().then(() => {
                  this.loading = false;
                  this.LoadPhotoList({
                    id: -1,
                    current: 1,
                    pageSize: 15
                  });
                  //this.checkedData = [];
                });
              }
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getVideoImage(file, call) {
      if (file && file.type.indexOf('video/') == 0) {
        var video = document.createElement('video');
        video.src = URL.createObjectURL(file);
        video.addEventListener('loadeddata', function () {
          this.currentTime = 1;
        });
        video.addEventListener('seeked', function () {
          this.width = this.videoWidth;
          this.height = this.videoHeight;
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');
          canvas.width = this.width;
          canvas.height = this.height;
          ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
          var image = {
            url: canvas.toDataURL('image/jpeg', 1),
            width: this.width,
            height: this.height,
            currentTime: this.currentTime,
            duration: this.duration
          };
          canvas.toBlob(function (blob) {
            image.blob = blob;
            typeof call == 'function' ? call.call(file, image) : null;
          }, 'image/jpeg');
        });
      }
    },
    /**
     * 处理上传
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    uploadFile(e) {
      let that = this;
      //此处执行POST提交方法
      return new Promise((reslove, reject) => {
        let formData = new FormData();
        that.getVideoImage(e, function (a) {
          formData.append('content', e);
          formData.append('cover', a.url);
          formData.append('type', 2);
          formData.append('group_id', that.groupInfo.id);
          formData.append('title', e.name);
          // that.$heshop
          //   .video(formData)
          //   .then(data => {
          //     reslove(data);
          //   })
          //   .catch(error => {
          //     reject(error);
          //   });
        });
      });
    },
    /**
     * 数据全选
     * @return {[type]} [description]
     */
    handleAllChange(value) {
      this.isIndeterminate = false;
      if (value) {
        this.checkedData = this.photoList;
      } else {
        this.checkedData = [];
      }
    },
    /**
     * 分页
     * @return {[ty3pe]} [description]
     */
    handleSizeChange(mumber = 1) {
      //重载当前分组列表
      this.LoadPhotoList({ id: this.groupInfo.id, current: mumber, pageSize: 15 });
      //this.checkedData = [];
    },
    /**
     * 执行删除数据
     * @return {[type]} [description]
     */
    handleDeletes() {
      this.loading = true;
      let arr = [];
      let type = 0;
      for (let index in this.checkedData) {
        let item = this.checkedData[index];
        if (item.id) {
          type = item.type;
          arr.push(item.id);
        }
      }
      if (type === 0) {
        if (arr[0]) {
          let id = arr[0];
          this.handleGroupDelete(id);
        }
      } else {
        this.$confirm('是否确认删除选中内容', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        })
          .then(() => {
            //处理素材删除
            this.DeletePhotoList(arr).then(data => {
              //重载当前分组列表
              this.LoadPhotoList({ id: this.groupInfo.id, current: 1, pageSize: 15 }).then(() => {
                this.loading = false;
              });
              //this.checkedData = [];
            });
          })
          .catch(() => {});
      }
    },
    /**
     * 照片移动至
     * @return {[type]} [description]
     */
    handleMoveList(group_id) {
      let _array = [];
      this.checkedData.map(item => {
        _array.push(item.id);
      });
      this.MovePhotoList({ list: _array, group_id }).then(res => {
        //重载当前分组列表
        this.LoadPhotoList({ id: this.groupInfo.id, current: 1, pageSize: 15 });
        //this.checkedData = [];
      });
    },
    /**
     * 内容重命名
     * @return {[type]} [description]
     */
    handleRenameItem(data) {
      if (data.type == 0) {
        this.handleEditClassify(data).then(res => {
          //重载当前分组列表
          this.UpdateGroupItem({ id: this.groupInfo.id, current: this.pageInfo.current, pageSize: 15 });
          //this.checkedData = [];
        });
      } else {
        if (data.id) {
          this.UpdatePhotoItem(data).then(res => {
            //重载当前分组列表
            this.LoadPhotoList({ id: this.groupInfo.id, current: this.pageInfo.current, pageSize: 15 });
            //this.checkedData = [];
          });
        }
      }
    },
    /**
     * 双击点击事件
     * @return {[type]} [description]
     */
    handleDblClick(value) {
      //执行数据加载
      if (value.type === 0) {
        //设置当前分组信息
        this.setGroupInfo(value);
        //面包屑
        this.breadcrumb.push(value);
        //重载当前分组列表
        this.LoadPhotoList({ id: value.id, current: 1, pageSize: 15 });
        //this.checkedData = [];
      }
    },
    /**
     * 处理样式数据
     * @return {[type]} [description]
     */
    handleCrumbItem(value, index) {
      if (index === 0) {
        this.upadteGroupInfo(value);
        this.breadcrumb = [value];
        //重置图片数据信息
        this.LoadPhotoList({ id: value.id, current: 1, pageSize: 15 });
        //this.checkedData = [];
      }
      if (index === 1) {
        if (this.breadcrumb.length > 2) {
          this.breadcrumb.pop();
        }
        let value = this.breadcrumb[1];
        this.upadteGroupInfo(value);
        //重置图片数据信息
        this.LoadPhotoList({ id: value.id, current: 1, pageSize: 15 });
        //this.checkedData = [];
      }
    }
  }
};
