import Vue from 'vue'
import Vuex from 'vuex'

import user from "./module/user";
import routes from "./module/routes";
import options from "./module/options";
import common from "./module/common";
import picture from './module/picture.js';
import getters from './module/getters.js';
import finish from './module/finish.js';
import goods from './module/goods.js';
import photo from './module/photo.js';
import video from './module/video.js';

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        user,
        routes,
        options,
        finish,
        common,
        goods,
        picture,
        photo,
        video
    },
    getters: getters
})

export default store